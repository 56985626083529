import { datadogRum } from "@datadog/browser-rum";
import { AlphaStack, Inline, Link, Text, Toast } from "@shopify/polaris";
import { DuplicateMinor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useCopyToClipboard } from "react-use";
import {
  carrierIcons,
  carrierNames,
  getCarrier,
  getTrackingCodeUrl,
  parseShippingMethod,
} from "~/common/utils/carrier";
import { ProofOfDelivery } from "../ProofOfDelivery/ProofOfDelivery";

interface DeliveryMethodProps {
  trackingCode: string;
  shippingMethod: string;
  deliveryImageUrl?: string;
}

const copyButtonStyle = { borderRadius: "50%", width: 40, height: 40, border: "none", marginTop: -4 };

export const DeliveryMethod: React.FC<DeliveryMethodProps> = ({ trackingCode, shippingMethod, deliveryImageUrl }) => {
  const [toastActive, setToastActive] = useState(false);
  const [_, copy] = useCopyToClipboard();
  const shipping = parseShippingMethod(shippingMethod);
  const carrier = getCarrier(shippingMethod);
  const carrierTrackingUrl = getTrackingCodeUrl(carrier, trackingCode);
  const icon = carrierIcons[carrier];
  const carrierName = carrierNames[carrier];

  const handleCopyToClipboard = () => {
    datadogRum.addAction("user_copy_tracking_id", { trackingCode });
    copy(trackingCode);
    setToastActive(true);
  };

  return shippingMethod ? (
    <ListGroup className="rounded-4 w-100">
      <ListGroup.Item className="p-3">
        <Inline align="space-between">
          <Inline>
            <img src={icon} alt={`${carrier} Icon`} style={{ borderRadius: "50%", height: 42, width: 42 }} />
            <AlphaStack gap="0">
              <Text as="span" variant="bodyMd" fontWeight="bold">
                Delivery by {carrierName}
              </Text>
              <Text as="span" variant="bodyMd" color="subdued">
                {shipping}
              </Text>
            </AlphaStack>
          </Inline>
          <Inline gap="1" blockAlign="center">
            <Text as="span" variant="bodyMd" fontWeight="bold">
              {trackingCode}
            </Text>
            <Button variant="outline-light" onClick={handleCopyToClipboard} style={copyButtonStyle}>
              <DuplicateMinor />
            </Button>
          </Inline>
        </Inline>
      </ListGroup.Item>
      {carrierTrackingUrl && (
        <ListGroup.Item className="p-3 text-center ">
          <Link removeUnderline={true} url={carrierTrackingUrl} external={true}>
            {`Track via ${carrierNames[carrier]}`}
          </Link>
        </ListGroup.Item>
      )}
      {deliveryImageUrl && <ProofOfDelivery deliveryImageUrl={deliveryImageUrl} />}
      {toastActive && <Toast content="Copied tracking code" duration={1000} onDismiss={() => setToastActive(false)} />}
    </ListGroup>
  ) : null;
};
